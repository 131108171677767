import { createApp } from 'vue'
import App from './index.vue'
// 引入VueRouter
import router from "@/router/router"
// 引入ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// ElementPlus 日期和时间本地化
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
// 引入自定义插件
import plugins from '@/plugins'
// 引入ApexCharts
import VueApexCharts from "vue3-apexcharts";
// 引入Vuex
import store from '@/store'

const app = createApp(App)

// 全局挂载
app.config.globalProperties.dayjs=dayjs

app.use(plugins)
    .use(router)
    .use(store)
    .use(VueApexCharts)
    .use(ElementPlus, {locale: zhCn,})
    .mount('#app')