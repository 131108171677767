<template>
  <footer class="footer footer-transparent d-print-none">
    <div class="container-xl">
      <div class="row text-center align-items-center flex-row-reverse">
        <div class="col-lg-auto ms-lg-auto">
          <ul class="list-inline list-inline-dots mb-0">
            <li class="list-inline-item">
              <a href="http://beian.miit.gov.cn/" rel="nofollow" class="link-secondary" target="_blank">
<!--                浙ICP备2023002754号-1-->
                浙ICP备14024466号-1
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-auto mt-3 mt-lg-0">
          <ul class="list-inline list-inline-dots mb-0">
            <li class="list-inline-item">
              Copyright &copy; 2022
              <a href="." class="link-secondary">找房联盟</a>.
              All rights reserved.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>