<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-xl">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
<!--        <a href="." class="me-1"><img src="/logo-home.png" height="40" alt="找房联盟"></a>-->
        <a href="."><img src="/img/fang/icon-128.png" height="40" alt="找房联盟"></a>
      </h1>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
          <ul class="navbar-nav">
            <li class="nav-item" v-for="(menu,index) in menus" :key="index">
              <router-link class="nav-link" :to="menu.path">
                <span class="nav-link-icon d-md-none d-lg-inline-block" v-html="menu.icon"></span>
                <span class="nav-link-title">{{ menu.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item d-none d-md-flex me-3">
          <div>
            <a href="/sponsor" class="btn" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon text-pink" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" /></svg>
              用爱发电
            </a>
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center m-3">
          <ThemeSwitch></ThemeSwitch>
        </div>
        <div class="nav-item dropdown" v-if="store.getters.access_token.length>0">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <span class="avatar avatar-sm"><img :src="avatar"></span>
            <div class="d-none d-xl-block ps-2">
              <div>{{ nickname }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="#" class="dropdown-item">我的关注</a>
            <a href="#" class="dropdown-item">个人中心</a>
            <a href="#" class="dropdown-item">使用反馈</a>
            <div class="dropdown-divider"></div>
            <a href="./settings.html" class="dropdown-item">设置</a>
            <a href="#" class="dropdown-item" @click="handleLogout">退出</a>
          </div>
        </div>
        <div class="nav-item d-md-flex me-3" v-else>
          <div class="btn-list">
            <a href="#" class="nav-link d-flex lh-1 text-reset p-0" @click.stop="handleLogin()">登录</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import {reactive, ref, watch} from "vue";
import ThemeSwitch from "@/components/ThemeSwitch";
import {getStore, setStore} from "@/utils/store";
import { useRoute } from 'vue-router'
const route = useRoute()
import { useStore } from "vuex"
const store = useStore()

let redirectURL = ref("")
let avatar = ref("")
let nickname = ref("")
let menus = reactive([
      {
        'name': '首页',
        'path': '/index',
        'icon': '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n' +
            '   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>\n' +
            '   <polyline points="3 17 9 11 13 15 21 7"></polyline>\n' +
            '   <polyline points="14 7 21 7 21 14"></polyline>\n' +
            '</svg>'
      },
      {
        'name': '下载插件',
        'path': '/download',
        'icon': '<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"\n' +
            '                           stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"\n' +
            '                           stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline\n' +
            '                          points="5 12 3 12 12 3 21 12 19 12"></polyline><path\n' +
            '                          d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path><path\n' +
            '                          d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path></svg>'
      },
      {
        'name': '常见问题',
        'path': '/fqa',
        'icon': '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-help" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n' +
            '   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>\n' +
            '   <circle cx="12" cy="12" r="9"></circle>\n' +
            '   <line x1="12" y1="17" x2="12" y2="17.01"></line>\n' +
            '   <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4"></path>\n' +
            '</svg>'
      },
    ])

redirectURL.value = encodeURIComponent(window.location.href)

const handleLogin = () => {
  store.commit("SET_SHOW_LOGIN", true)
}

const handleLogout = () => {
  store.dispatch("LogOut").then(()=> {
    window.location.href = "/"
  })
}

watch(()=>store.getters.access_token, ()=> {
  const token = store.getters.access_token
  if(token.length > 0) {
    // console.log(getStore({name:'userInfo', type: 'session'}))
    const obj_userInfo = getStore({name:'userInfo', type: 'session'})
    avatar.value = obj_userInfo.avatar
    nickname.value = obj_userInfo.nickname
  }
}, {immediate:true})
</script>