<template>
  <div class="sticky-top">
    <PageHeader />
  </div>
  <div class="page-wrapper">
    <div class="container-xl">
      <div class="col-12" v-show="!$canSupportedInformation">
        <div class="card">
          <div class="card-status-top bg-danger"></div>
          <div class="card-stamp">
            <div class="card-stamp-icon bg-yellow">
              <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path><path d="M9 17v1a3 3 0 0 0 6 0v-1"></path></svg>
            </div>
          </div>
          <div class="card-body">
            <h3 class="card-title text-orange bg-transparent text-center">当前浏览器环境不支持，插件仅支持电脑版的谷歌浏览器（Chrome）</h3>
          </div>
        </div>
      </div>
      <div class="page-header d-print-none">
        <div class="row g-1 align-items-center">
          <div class="col-lg-6 col-md-12">
            <h2 class="page-title">
              你关心的 才有价值
            </h2>
            <div class="page-pretitle">
              找房联盟插件  准确率99.99%  非爬虫
            </div>
          </div>
          <div class="col-lg-6 col-md-12 ms-auto d-print-none">
            <div class="btn-list">
              <div class="input-group mb-2">
                <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedItem}}
                </button>
                <div class="dropdown-menu" style="">
                  <span class="dropdown-item" @click="handleItemClick('小区名 例:吉庆院', '小区名','1')">小区名 例:吉庆院</span>
                  <span class="dropdown-item" @click="handleItemClick('城市 例:杭州', '城市', '2')">城市 例:杭州</span>
                  <span class="dropdown-item" @click="handleItemClick('区域 例:西湖', '区域', '3')">区域 例:西湖</span>
                  <span class="dropdown-item" @click="handleItemClick('商圈 例:黄龙', '商圈', '4')">商圈 例:黄龙</span>
                </div>
                <input type="text" class="form-control" :placeholder='"请输入" + itemPlaceholder' v-model="inp_name" @keyup.enter="handleSearch">
                <span class="input-group-text">
                  <a href="#" @click="handleClearSearch" class="link-secondary" data-bs-toggle="tooltip" aria-label="Clear search" data-bs-original-title="Clear search">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                         stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </a>
                </span>
                <button class="btn" type="button" @click="handleSearch">搜索</button>
              </div>
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="px-1 mt-1" v-for="(historyKey, index) in inp_history_key" :key="index">
                <a href="#" @click="handleSearchQuick">{{ historyKey }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-deck row-cards">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <SignInModal ref="modalLoginRef"></SignInModal>
  </div>
  <PageFooter />
</template>

<script setup>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import {onMounted, ref, watch} from "vue";
import {sendMessageToChromePlugin} from "@/utils"
import {useStore} from 'vuex'
import router from '@/router/router'
import {useRoute} from "vue-router";
import SignInModal from "@/views/SignInModal.vue";

// const {mapState, mapActions} = createNamespacedHelpers('community');// 通过这个函数来辅助我们找到user模块

const store = useStore()
const route = useRoute()

const modalLoginRef = ref(null)

const inp_name = ref("")
let inp_history_key = ref(["吉庆院","蓝光圣菲town城"])

const selectedItem = ref('小区名')
const itemPlaceholder = ref('小区名 例:吉庆院')

const handleItemClick = (a, b, c) => {
  itemPlaceholder.value = a
  selectedItem.value = b
  store.dispatch("set_condition_t", c)
}

const handleSearch = () => {
  let tmp_inp_history_key = JSON.parse(localStorage.getItem("inp_history_key"))
  if (tmp_inp_history_key != null) {
    inp_history_key.value = tmp_inp_history_key
  }
  arrayDeduplication(inp_name.value)
  localStorage.setItem("inp_history_key",JSON.stringify(inp_history_key.value))
  store.dispatch("set_condition_q", inp_name.value)
  if(route.name !== "PriceTrend") {
    router.push("/index")
  }
}

const handleSearchQuick = (e) => {
  inp_name.value = e.target.text
  store.dispatch("set_condition_q", inp_name.value)
  if(route.name !== "PriceTrend") {
    router.push("/index")
  }
}

const handleClearSearch = () => {
  inp_name.value = ""
  store.dispatch("set_condition_q", inp_name.value)
  if(route.name !== "PriceTrend") {
    router.push("/index")
  }
}

const arrayDeduplication = (newValue) => {
  if(newValue !==""){
    inp_history_key.value.unshift(newValue)
    let newArr = inp_history_key.value.reduce((pre,cur)=>{
      if(!pre.includes(cur)){
        return pre.concat(cur)
      }else{
        return pre
      }
    },[])
    if (newArr.length > 5){
      newArr.pop()
    }
    inp_history_key.value = newArr
  }
}

onMounted(() => {
  // 检测是否安装插件
  sendMessageToChromePlugin("hasExtension",(res)=>{
    if (res && res.hasExtension) {
      localStorage.setItem("hasExtension",1);
    } else {
      localStorage.setItem("hasExtension",0);
    }
  })
  // 本地查询历史记录
  let tmp_inp_history_key = JSON.parse(localStorage.getItem("inp_history_key"))
  if (tmp_inp_history_key != null) {
    inp_history_key.value = tmp_inp_history_key;
  }
})

watch(() => store.getters.showLogin, (newValue, oldValue) => {
  if(newValue){
    modalLoginRef.value.showWxlogin();
  }
})

</script>