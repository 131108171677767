import { isMobile, isChrome } from "mobile-device-detect";
import dayjs from "dayjs";

export default {
    install(app) {
        app.config.globalProperties.$chromePluginVersion = '0.2.2'
        app.config.globalProperties.$beianNo = '浙ICP备14024466号-1'
        app.config.globalProperties.$canSupportedInformation = !isMobile && isChrome
        app.config.globalProperties.$updateDiff = (val) => {
            // console.log("val:", val)
            if(val===""||val===null){
                return "-"
            }
            let date1 = dayjs()
            let date2 = dayjs(val)
            let updateDurationDays = date1.diff(date2,"d")
            return updateDurationDays === 0? "今天更新":(updateDurationDays===1?"昨天更新":updateDurationDays + "天前更新")
        }
    }
}