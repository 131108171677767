<template>
  <div class="modal modal-blur fade" id="modal-wxlogin" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="" @click.prevent="handleCloseWxLogin" ref="closeWxloginBtn"></button>
        <div class="modal-status bg-success"></div>
        <div class="modal-body text-center py-4">
          <wxlogin
              appid="wx0cc44650b95e1d05"
              :scope="'snsapi_login'"
              :state="'WX-LOGIN'"
              :redirect_uri=redirect_uri
              rel="external nofollow">
          </wxlogin>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn" style="display:none;" data-bs-toggle="modal" data-bs-target="#modal-wxlogin" @click.prevent="handleShowWxLogin" ref="showWxloginBtn"></button>
</template>

<script setup>
import {ref, defineExpose, watch} from "vue";
import wxlogin from 'vue-wxlogin';
import { useStore } from "vuex"

const store = useStore()
const redirect_uri = encodeURIComponent(window.location.origin + '/account/authredirect')
const showWxloginBtn = ref()
const closeWxloginBtn = ref()

const handleShowWxLogin = () => {
  showWxloginBtn.value.click();
}
const handleCloseWxLogin = () => {
  closeWxloginBtn.value.click();
  store.commit("SET_SHOW_LOGIN", false)
}

watch(() => store.getters.showLogin, (newValue, oldValue) => {
  if(newValue == "close"){
    handleCloseWxLogin()
  }
})

defineExpose({showWxlogin:handleShowWxLogin});
</script>

<style scoped>

</style>