import {createRouter, createWebHistory} from "vue-router";
// 引入
// import Index from "@/views/Index";
// import Trend from "@/views/Trend";

// 路由信息
let routes = [
    {
        path: "/",
        redirect: {name: 'PriceTrend'}
        // redirect: {name: 'FQA'}
    },
    {
        path: "/download",
        name: "IndexView",
        meta: {isAuth: false, title:"插件下载 - 找房联盟，数字化、精细化找房 - 你关心的 才有价值"},
        component: () => import("@/views/IndexView.vue"),
    },
    {
        path: "/index",
        name: "PriceTrend",
        meta: {isAuth: false, title:"首页 - 挂牌趋势 - 找房联盟，数字化、精细化找房 - 你关心的 才有价值"},
        component: () => import("@/views/PriceTrend.vue"),
    },
    {
        path: "/details",
        name: "PriceDetails",
        meta: {isAuth: false, title:"详情 - 挂牌趋势 - 找房联盟，数字化、精细化找房 - 你关心的 才有价值"},
        component: () => import("@/views/PriceDetails.vue"),
    },
    {
        path: "/fqa",
        name: "FQA",
        meta: {isAuth: false, title:"常见问题 - 找房联盟，数字化、精细化找房 - 你关心的 才有价值"},
        component: () => import("@/views/FQA.vue"),
    },
    {
        path: "/sponsor",
        name: "SponsorView",
        meta: {isAuth: false, title:"赞助 - 找房联盟，数字化、精细化找房 - 你关心的 才有价值"},
        component: () => import("@/views/SponsorView.vue"),
    },
];

// 路由器
const router = createRouter({
    history: createWebHistory(), // HTML5模式
    routes,
});

// 全局前置路由守卫
router.beforeEach((to, from, next)=>{
    // console.log('to :>> ', to);
    // console.log('from :>> ', from);
    next();
})

// 全局后置路由
router.afterEach((to, from)=>{
    document.title = to.meta.title;
})

export default router;